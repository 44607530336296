// Polyfills
import './modules/polyfills/polyfills';
// Types
import './types/globals';
import { KeyValuePairs } from './types/common';
import { DOT } from './classes/dot.class';
// Methods
import { syncSid } from './modules/sid/sync';
import { addSpenttime } from './modules/spenttime/spenttime';
import { addLinkdecoration } from './modules/linkdecoration/linkdecoration';
import { configure, _fireAfterCfgEvent } from './modules/config/config';
import { handleImpress } from './modules/impress/impress';
import { finishLazyHitting, initLazyHitting } from './modules/lazyHitting/lazyHitting';
import { initMousedown } from './modules/events/mousedown/mousedown';
import { addLoad, initLoad } from './modules/load/load';
import { addError, initError } from './modules/error/error';
import { initIframeBridge } from './modules/utils/html/iframe';
// Constants
import { LAZY_HITTING_ENABLED, SERVICE_VARIANT } from './constants/index';

// IVA npm library
import '@iva/refresher';
import { startErrorHandling } from '@iva/error-reporter';
import { marker } from '@iva/marker';
import { SID_UTILS } from '@iva/utils';

startErrorHandling({
  endpoint: 'dot-static',
  scriptName: 'dot-big.js',
});

marker();
SID_UTILS.removeInvalidSidCookie();

/**
 * DOT BIG is currently a clone of DOT SMALL and will probably be removed in the future.
 * This class should be the same as DOT_SMALL in ./dot-small.ts
 */
class DOT_SMALL extends DOT {
  constructor() {
    super(SERVICE_VARIANT.SMALL);
    syncSid(this);

    /* Include all additional modules */
    addLoad(this);
    addError(this);
    addSpenttime(this);
    addLinkdecoration(this);
  }

  /**
   * Returns new DOT_SMALL instance
   */
  public getNewInstance(): DOT_SMALL {
    return new DOT_SMALL();
  }

  /**
   * Configures DOT instance
   *
   * @param customConfig config data object
   * @param impressCallback called when impress is sent
   */
  public cfg(customConfig: KeyValuePairs<unknown>, impressCallback?: () => void): void {
    configure(this, customConfig);

    if (customConfig) {
      if (customConfig.lazyLoadingEnabled === true) {
        initLazyHitting(this);
      } else if (customConfig.lazyLoadingEnabled === false && this.lazyHittingStatus === LAZY_HITTING_ENABLED) {
        finishLazyHitting(this, customConfig);
        return;
      }
    }

    if (this._cfg.impress) {
      handleImpress(this, impressCallback);
    }

    if (this.configured) {
      return;
    }
    this.configured = true;

    _fireAfterCfgEvent(this);

    if (this._cfg.mousedown) {
      initMousedown(this);
    }

    if (this._cfg.load) {
      initLoad(this);
    }

    if (this._cfg.error) {
      initError(this);
    }

    if (this._cfg.iframeBridge) {
      initIframeBridge(this);
    }
  }
}

window.DOT = new DOT_SMALL();
